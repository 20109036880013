import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState,useContext } from "react";
import "./payment_Test.css";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import{userContext} from "../QuickPay/UserContextProvider"

function Payments() {

  const navigate = useNavigate();
  const { paymentpage, setpaymentpage } = useContext(userContext);

  const [mobile, setMobile] = useState("");
  const [doctor, setDoctor] = useState("");
  const [doctorID, setDoctorID] = useState("");
  const [appDate, setAppDate] = useState("");
  const [name, setName] = useState("");
  const [uhidOptions, setUhidOptions] = useState([]); 
  const [selectedUhid, setSelectedUhid] = useState(""); 
  const [appTime, setAppTime] = useState("");
  const [consFees, setConsFees] = useState("");
  const [email, setEmail] = useState("");
  const [appid, setAppid] = useState("");

  const fetchComments = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.get(
        `https://www.relainstitute.in/relalive/api/HIS/GetPortal_PatDtl_New?mobileno=${mobile}`
      );

      if (response && response.data) {
        setUhidOptions(response.data);

        if (response.data.length === 0) {
          alert("There is no appointment for the entered mobile number.");
          resetFields();
          return;
        }
        
        const firstAppointment = response.data[0];
        setName(firstAppointment.patientName);
        setDoctor(firstAppointment.doctor);
        setAppDate(firstAppointment.appDate.split(' ')[0]);
        setAppTime(firstAppointment.appTime);
        setConsFees(firstAppointment.charges);
        setEmail(firstAppointment.email);
        setDoctorID(firstAppointment.doctorId);
        setAppid(firstAppointment.appointmentId);
        setSelectedUhid(firstAppointment.uhid); 
      } else {
        alert("Invalid response from the server. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching appointment details:", error);
      alert("An error occurred while fetching the appointment details. Please try again.");
    }
  };

  const resetFields = () => {
    setName("");
    setDoctor("");
    setAppDate("");
    setAppTime("");
    setConsFees("");
    setEmail("");
    setDoctorID("");
    setAppid("");
    setSelectedUhid("");
  };

  const handleUhidChange = (event) => {
    const selectedId = event.target.value;
    setSelectedUhid(selectedId);

    const selectedAppointment = uhidOptions.find((appointment) => appointment.uhid === selectedId);
    if (selectedAppointment) {
      setName(selectedAppointment.patientName);
      setDoctor(selectedAppointment.doctor);
      setAppDate(selectedAppointment.appDate.split(' ')[0]);
      setAppTime(selectedAppointment.appTime);
      setConsFees(selectedAppointment.charges);
      setEmail(selectedAppointment.email);
      setDoctorID(selectedAppointment.doctorId);
      setAppid(selectedAppointment.appointmentId);
    }
  };

  // window.scrollTo(0, 0);
  // navigate("/quickpay", { replace: true });


  const handleSubmit2 = async (event) => {
    event.preventDefault();
    try {
      const process = () => {
        const date = new Date();
        const year = date.getFullYear();
        const time = date.getMinutes();
        const sum = selectedUhid + year + time;
        return sum;
      };
      const currentDate = new Date().toISOString().slice(0, 10);
      await axios.post(
        "https://www.relainstitute.in/relalive/api/HIS/updateOnlinePayment",
        {
          RefID: process(),
          RefType: "PayAtHospital",
          PatientID: selectedUhid,
          PatientName: name,
          MobileNo: mobile,
          EmailID: email,
          DoctorID: doctorID,
          TransactionDate: currentDate,
          TransactionID: "",
          TransactionAmount: consFees,
          PaymentMode: "",
          StatusCode: "",
          StatusMsg: "",
          PaymentStatus: "Pending",
          Remarks: "",
          IsActiveflg: 0,
          CreatedCode: "MEFTEC",
          bloodGroup: "22",
          Religion: "7",
          PrefferedLanguage: "17",
          APPStartDate: appDate,
          APPEndDate: appTime,
          AppointmentId: appid,
        }
      );
      window.location.href = `http://180.235.120.78/Appointment_PayAtHospital/?PatientID=${selectedUhid}&PatientName=${name}&chargerate=${consFees}&email=${email}&mobileno=${mobile}&processingid=${process()}`;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Row>
        <Form>
          <div>
            <div
              style={{
                textAlign: "left",
                border: "1px solid black",
                borderRadius: "5px",
                position: "relative",
                padding: "10px",
                marginTop: "60px",
              }}
            >
              <h2 className="absolute">Pay at Hospital</h2>

              <Row className="mb-5 mt-5 left">
                <Form.Group as={Col}>
                  <Form.Label className="label" id="mobile">
                    Mobile No:
                  </Form.Label>
                  <Form.Control
                    className="input"
                    type="text"
                    value={mobile}
                    maxLength={10}
                    required
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,10}$/.test(value)) {
                        setMobile(value);
                      }
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label></Form.Label>
                  <Form.Control
                    value="Search"
                    className="search-button1 btn-primary"
                    onClick={fetchComments}
                    readOnly
                  />
                </Form.Group>
              </Row>

              <Row className="mb-5 left">
                <Form.Group as={Col}>
                  <Form.Label className="label">UHID</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedUhid}
                    onChange={handleUhidChange}
                    className="input">
                    <option value="">Select UHID</option>
                    {uhidOptions.map((appointment) => (
                      <option key={appointment.uhid} value={appointment.uhid}>
                        {appointment.uhid}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label className="label">Patient Name</Form.Label>
                  <Form.Control
                    className="input"
                    name="name"
                    type="text"
                    value={name}
                    readOnly
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="label">Doctor Name</Form.Label>
                  <Form.Control
                    className="input"
                    name="doctor"
                    type="text"
                    value={doctor}
                    readOnly
                  />
                </Form.Group>
              </Row>

              <Row className="mb-5 left">
                <Form.Group as={Col}>
                  <Form.Label className="label">Appointment Date</Form.Label>
                  <Form.Control
                    className="input"
                    name="appDate"
                    type="text"
                    value={appDate}
                    readOnly
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="label">Appointment Time</Form.Label>
                  <Form.Control
                    className="input"
                    name="appTime"
                    type="text"
                    value={appTime}
                    readOnly
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="label">Consultation Fee</Form.Label>
                  <Form.Control
                    className="input"
                    name="consfees"
                    type="text"
                    value={consFees}
                    readOnly
                  />
                </Form.Group>
              </Row>
            </div>

            <div
              style={{
                paddingTop: "10px",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              <Button className="primary" type="button" onClick={handleSubmit2}>
            
                PAY ONLINE
              </Button>
            </div>
          </div>
        </Form>
      </Row>
    </Container>
  );
}

export default Payments;
